import { styled } from "stitches.config";
import { Logo } from "./header/Logo";
import { ProgressBar } from "src/ecommerce/components/partials/ProgressBar";
import MerchantImage from "../../../assets/svg/product/media-expert.svg";

const HeaderStyled = styled("header", {
  padding: "1rem 0",
  display: "flex",
  alignItems: "center",
});

const PartnerStyled = styled("span", {
  display: "flex",
  marginRight: "7px",
  fontSize: "10px",
  fontWeight: "semibold",
  "&::before": {
    content: " ",
    display: "inline-block",
    margin: "0 16px",
    width: "1px",
    height: "16px",
    background: "#adb9c1",
  },
});

export function Header({ activeStep, ...props }) {
  return (
    <HeaderStyled className="mb-6 pt-5" {...props}>
      <div className="container px-lg-0">
        <div className="row d-flex">
          <div className="col-lg-7 col-24 mt-1 mb-lg-2 mb-5 text-center text-lg-start ps-lg-0">
            <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
              <Logo />
              <PartnerStyled className="">PARTNER</PartnerStyled>
              <MerchantImage width="97px" height="100%" />
            </div>
          </div>
          <div className="col-lg-17 col-24 d-flex align-items-center pe-lg-0 ps-lg-8">
            <ProgressBar className="w-100 ps-lg-5" activeStep={activeStep} />
          </div>
        </div>
      </div>
    </HeaderStyled>
  );
}
