import Link from "next/link";
import React from "react";

function ContactMessage() {
  return (
    <div className="d-flex justify-content-center">
      <p className="fs-xxs">
        Masz pytania lub potrzebujesz pomocy?
        <Link href="/kontakt" passHref>
          <a target="_blank"> Skontaktuj się z nami</a>
        </Link>
      </p>
    </div>
  );
}

export default ContactMessage;
