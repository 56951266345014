export class HttpError extends Error {
  constructor(status, message, response) {
    super(message);
    this.status = status;
    this.message = message;
    this.response = response;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}
